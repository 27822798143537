import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["results", "selected", "ids", "input"]
  static values = { maxItems: Number }

  connect() {
    this.maxItemsValue = 3
    this.selectedItems = new Map()
    this.currentFocus = -1
    this.boundClickOutside = this.clickOutside.bind(this)
    document.addEventListener("click", this.boundClickOutside)
  }

  disconnect() {
    document.removeEventListener("click", this.boundClickOutside)
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hideResults()
    }
  }

  hideResults() {
    this.resultsTarget.classList.add("hidden")
    this.currentFocus = -1
  }

  search() {
    const query = this.inputTarget.value
    if (query.length < 3) {
      this.hideResults()
      return
    }

    this.fetchResults(query)
  }

  fetchResults(query) {
    fetch(`/projects/${this.element.dataset.projectId}/contents/search?query=${query}`)
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.resultsTarget.classList.remove("hidden")
        this.currentFocus = -1
      })
  }

  navigate(event) {
    const items = this.resultsTarget.querySelectorAll("[data-action='click->content-selector#select']")
    if (!items.length) return

    if (event.key === "ArrowDown") {
      this.currentFocus++
      if (this.currentFocus >= items.length) this.currentFocus = 0
    } else if (event.key === "ArrowUp") {
      this.currentFocus--
      if (this.currentFocus < 0) this.currentFocus = items.length - 1
    } else if (event.key === "Enter") {
      event.preventDefault()
      if (this.currentFocus > -1) {
        items[this.currentFocus].click()
      }
      return
    } else if (event.key === "Escape") {
      this.hideResults()
      return
    } else {
      return
    }

    this.addActive(items)
  }

  addActive(items) {
    items.forEach((item, index) => {
      if (index === this.currentFocus) {
        item.classList.add("bg-blue-100")
      } else {
        item.classList.remove("bg-blue-100")
      }
    })
  }

  select(event) {
    const contentId = event.currentTarget.dataset.contentId
    const contentTitle = event.currentTarget.textContent.trim()

    if (this.selectedItems.size >= this.maxItemsValue) {
      alert(`You can only select up to ${this.maxItemsValue} items.`)
      return
    }

    this.selectedItems.set(contentId, contentTitle)
    this.updateSelectedContents()
    this.updateHiddenField()
    this.hideResults()
    this.inputTarget.value = ""
    this.inputTarget.focus()
  }

  remove(event) {
    const contentId = event.currentTarget.dataset.contentId
    this.selectedItems.delete(contentId)
    this.updateSelectedContents()
    this.updateHiddenField()
  }

  updateSelectedContents() {
    this.selectedTarget.innerHTML = Array.from(this.selectedItems).map(([id, title]) => {
      return `<div class="inline-flex items-center bg-blue-100 text-blue-800 rounded px-2 py-1 text-sm mr-2 mb-2">
                <span>${title}</span>
                <button type="button" class="ml-1 text-blue-600 hover:text-blue-800" data-action="content-selector#remove" data-content-id="${id}">×</button>
              </div>`
    }).join('')
  }

  updateHiddenField() {
    this.idsTarget.value = Array.from(this.selectedItems.keys()).join(',')
  }

  inputFocus() {
    if (this.inputTarget.value.length >= 3) {
      this.fetchResults(this.inputTarget.value)
    }
  }

  addSource(event) {
    const contentId = event.detail.contentId
    const contentTitle = event.detail.contentTitle

    if (this.selectedItems.size >= this.maxItemsValue) {
      alert(`You can only select up to ${this.maxItemsValue} items.`)
      return
    }

    this.selectedItems.set(contentId, contentTitle)
    this.updateSelectedContents()
    this.updateHiddenField()
  }
}