import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["sourcesList"]

  connect() {
    if (this.hasSourcesListTarget) {
      useTransition(this, {
        element: this.sourcesListTarget,
        enterActive: 'transition ease-out duration-300',
        enterFrom: 'transform opacity-0 scale-95',
        enterTo: 'transform opacity-100 scale-100',
        leaveActive: 'transition ease-in duration-300',
        leaveFrom: 'transform opacity-100 scale-100',
        leaveTo: 'transform opacity-0 scale-95',
      })
    }
  }

  toggleSources(event) {
    event.preventDefault()
    if (this.hasSourcesListTarget) {
      this.sourcesListTarget.classList.toggle('hidden')
    }
  }

  addSource(event) {
    event.preventDefault()
    const contentId = event.currentTarget.dataset.contentId
    const contentTitle = event.currentTarget.dataset.contentTitle

    const addSourceEvent = new CustomEvent('content-selector:add-source', {
      bubbles: true,
      detail: { contentId, contentTitle }
    })

    window.dispatchEvent(addSourceEvent)
  }
}