import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  addSource(event) {
    const contentSelectorController = this.application.getControllerForElementAndIdentifier(
      this.element.querySelector("[data-controller~='content-selector']"),
      "content-selector"
    )

    if (contentSelectorController) {
      contentSelectorController.addSource(event)
    }
  }
}